<div class="header-top">
    <div class="wrapper cf">
        <ul class="our-network">
            <li class="our-network-our">
                <p class="label">Our Network:</p>
            </li>
            <li><a href="https://tubezog.com" title="Free Sex Videos" target="_blank" class="link">Tubezog</a></li>
            <li><a href="https://pornhubvn.com" title="Amateur Porn Tube" target="_blank" class="link">Pornhubvn</a></li>
            <li><a href="https://spankbang.com" title="Free Porn Tube" target="_blank" class="link">Spankbang</a></li>
            <li><a href="https://gofucker.net" title="HD Porn Videos" target="_blank" class="link">Gofucker</a></li>
        </ul>
        <ul class="login-menu jsLoginMenuContainer">
        </ul>




    </div>
</div>

<div class="header">
    <div class="wrapper">

        <div class="header-content">

            <div class="header-item">

                <div class="header-logo-container">
                    <a class="header-logo" href="/ " title="xvideo17 Free Porn Clips">xvideo17 Free Porn Clips</a>
                </div>

            </div>

            <div class="header-item wide">

                <div class="search-block">
                    <form id="search-form" action="">
                        <input class="search-text" id="search-query" name="s" type="text" autocomplete="off"
                            placeholder="Search Xvideo17 Free Porn Clips" value="" [(ngModel)]="keyword"
                            (change)="onChangeKeyword()" />
                        <button type="submit" class="search-button" id="search-submit" title="Search"><i
                                class="icon icon-search"></i></button>
                        <button type="button" class="search-clear-button jsSearchClearButton"><i
                                class="icon icon-close-small"></i></button>
                        <div id="autocomplete" class="search-autocomplete" style="display: none;"></div>
                    </form>
                </div>

            </div>

            <div class="header-item">

                <div class="right-menu-container">
                    <ul class="right-menu jsRightMenuContainer">
                        <li>
                            <div class="dropdown-container">

                                <form id="jsOrientationForm" method="post" action="/" class="hidden">
                                    <input name="submit-orientation" type="hidden" value="" />
                                    <input id="jsOrientationHidden" name="orientations[gay]" type="hidden" value="1" />
                                </form>
                                <div class="dropdown-block invisible jsSelectNiche">
                                </div>
                            </div>
                        </li>
                        <li>
                        </li>
                    </ul>
                </div>

            </div>

        </div>


        <ul class="header-menu jsMenuContainer">
            <li >
                <a [routerLink]="['/category','us-uk']" class="header-menu-button">American</a>
            </li>
            <li >
                <a [routerLink]="['/category','teen-18-plus']" class="header-menu-button">Teen (18+)</a>
            </li>
            <li >
                <a [routerLink]="['/category','russian']" class="header-menu-button">Russian</a>
            </li>
            <li >
                <a [routerLink]="['/category','japanese']" class="header-menu-button">Japanese</a>
            </li>
            <li>
                <a class="navbar-item" [routerLink]="['/pornstars']" class="header-menu-button">Pornstars</a>
            </li>
        </ul>
    </div>
</div>