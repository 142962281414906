import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
const lodash = require('lodash');
interface SeoTag {
    title?: string;
    description?: string;
    avatar?: string;
    site_name?: string;
    url?: string;
    isIndex?: boolean;
    keywords?: string;
    type?: string
}
@Injectable({
    providedIn: 'root'
})
export class SeoService {
    constructor(@Inject(DOCUMENT) private doc: any, private title: Title, private meta: Meta) { }
    updateTag(data: SeoTag) {
        const {
            title,
            description,
            avatar,
            site_name,
            url,
            isIndex,
            keywords,
            type
        } = data;
        if (title && title.length > 0) {
            this.meta.updateTag({
                name: 'title',
                content: title
            });
            this.meta.updateTag({
                property: 'og:title',
                content: title
            });
            this.meta.updateTag({
                property: 'twitter:title',
                content: title
            });
            this.title.setTitle(title);
        }
        if (keywords && keywords.length > 0) {
            this.meta.updateTag({
                name: 'keywords',
                content: keywords
            });
        }
        if (isIndex) {
            this.meta.updateTag({
                name: 'robots',
                content: 'index, follow'
            });
        }
        if (description && description.length > 0) {
            
            let destmp =lodash.replace(description, '<p>', '');
            destmp =lodash.replace(destmp, '</p>', '');

            this.meta.updateTag({
                name: 'description',
                content: destmp
            });
            this.meta.updateTag({
                property: 'og:description',
                content: destmp
            });
            this.meta.updateTag({
                property: 'twitter:description',
                content: destmp
            });
        }
        if (avatar && avatar.length > 0) {
            this.meta.updateTag({
                property: 'og:image',
                content: avatar
            });
            this.meta.updateTag({
                property: 'twitter:image',
                content: avatar
            });
        }
        if (site_name && site_name.length > 0) {
            this.meta.updateTag({
                property: 'og:site_name',
                content: site_name
            });
        }
        if (url && url.length > 0) {
            this.meta.updateTag({
                property: 'og:url',
                content: url
            });
            let links = this.doc.getElementsByTagName("link");
            links[1].setAttribute('href', url);
        }
        if (type && type.length > 0) {
            this.meta.updateTag({
                property: 'og:type',
                content: type
            });
        }

    }
}