import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  // changeDetection: ChangeDetectionStrategy.Default,
})
export class FooterComponent {
  title = 'angular';
}
